import * as PIXI from 'pixi.js';
import {Howl, Howler} from 'howler/src/howler.core';

// Create sprite from multiple audio files: https://github.com/tonistiigi/audiosprite


var isSoundsStarted = false;

var akkordeon = new Howl({
  src: [require("../sounds/Akkordeon.mp3")],
  loop: true,
  preload: true
});
window.foo = akkordeon;
var bass = new Howl({
  src: [require("../sounds/Bass.mp3")],
  loop: true,
  preload: true
});

var camera = new Howl({
  src: [require("../sounds/Camera.mp3")],
  loop: true,
  preload: true
});

var cello = new Howl({
  src: [require("../sounds/Cello.mp3")],
  loop: true,
  preload: true
});

var git = new Howl({
  src: [require("../sounds/Git.mp3")],
  loop: true,
  preload: true
});

var handclap = new Howl({
  src: [require("../sounds/handclap.mp3")],
  loop: true,
  preload: true
});

var klavier = new Howl({
  src: [require("../sounds/Klavier.mp3")],
  loop: true,
  preload: true
});

var synth = new Howl({
  src: [require("../sounds/synth.mp3")],
  loop: true,
  preload: true
});

var drums = new Howl({
  src: [require("../sounds/drums.mp3")],
  loop: true,
  preload: true
});

var spriteDefinition = {
  frank: {
    sprites: [
      require("../img/Frank-1-rgb.jpg"),
      require("../img/Frank-2-rgb.jpg")
    ],
    config: {
      name: "frank",
      position: 1,
      sound: handclap
    }
  },
  konstantin: {
    sprites: [
      require("../img/Konstantin-1-rgb.jpg"),
      require("../img/Konstantin-2-rgb.jpg")
    ],
    config: {
      name: "Konstantin",
      position: 2,
      sound: klavier
    }
  },
  sepp: {
    sprites: [
      require("../img/Sepp-1-rgb.jpg"),
      require("../img/Sepp-2-rgb.jpg")
    ],
    config: {
      name: "Sepp",
      position: 3,
      sound: git
    }
  },
  hanna: {
    sprites: [
      require("../img/Hanna-1-rgb.jpg"),
      require("../img/Hanna-2-rgb.jpg")
    ],
    config: {
      name: "Hanna",
      position: 4,
      sound: handclap
    }
  },
  susanne: {
    sprites: [
      require("../img/Susanne-1-rgb.jpg"),
      require("../img/Susanne-2-rgb.jpg")
    ],
    config: {
      name: "Susanne",
      position: 5,
      sound: akkordeon
    }
  },
  emilia: {
    sprites: [
      require("../img/Emilia-1-rgb.jpg"),
      require("../img/Emilia-2-rgb.jpg")
    ],
    config: {
      name: "Emilia",
      position: 6,
      sound: cello
    }
  },
  falk: {
    sprites: [
      require("../img/Falk-1-rgb.jpg"),
      require("../img/Falk-2-rgb.jpg")
    ],
    config: {
      name: "Falk",
      position: 7,
      sound: synth
    }
  },
  hans: {
    sprites: [
      require("../img/Hans-1-rgb.jpg"),
      require("../img/Hans-2-rgb.jpg")
    ],
    config: {
      name: "hans",
      position: 8,
      sound: bass
    }
  },
  karine: {
    sprites: [
      require("../img/Karine-1-rgb.jpg"),
      require("../img/Karine-2-rgb.jpg")
    ],
    config: {
      name: "Karine",
      position: 9,
      sound: camera
    }
  },
  anna: {
    sprites: [
      require("../img/Anna-1.jpg"),
      require("../img/Anna-2.jpg")
    ],
    config: {
      name: "Anna",
      position: 10,
      sound: drums
    }
  },

};

// The application will create a renderer using WebGL, if possible,
// with a fallback to a canvas render. It will also setup the ticker
// and the root stage PIXI.Container
// http://pixijs.download/release/docs/PIXI.Application.html

const app = new PIXI.Application(
  {
    width: 1400,         // default: 800
    height: 400,        // default: 600
    //antialias: true,    // default: false
    //transparent: false, // default: false
    resolution: 1,       // default: 1
    backgroundColor: 0xffffff,
    view: document.getElementById('pixican')
  }
);
const animationSpeed = 0.05;
const scaleFactor = 0.2;
const padding = 120;
const marginLeft = 50;
let anim = null;
// The application will create a canvas element for you that you
// can then insert into the DOM
//document.body.appendChild(app.view);
app.stop();
app.loader
  .load(onAssetsLoaded);

window.app = app;

function setConfiguration(sprite, character) {
  sprite.x = marginLeft + (padding * (character.config.position - 1));
  sprite.y = app.screen.height / 2;
  sprite.scale.set(scaleFactor)
  sprite.anchor.set(0.5);
  sprite.loop = true;
  sprite.animationSpeed = animationSpeed;

  // Opt-in to interactivity
  sprite.interactive = true;

// Shows hand cursor
  sprite.buttonMode = true;
  //sprite.sound = character.config.sound;
  sprite.config = character.config;

// Pointers normalize touch and mouse
  sprite.on('pointerdown', onClick);
}

function initCharacter(character) {
  // create an array of textures from an image path
  const frames = [];

  character.sprites.forEach(sprite => frames.push(PIXI.Texture.from(sprite)));

  // http://pixijs.download/release/docs/PIXI.AnimatedSprite.html
  anim = new PIXI.AnimatedSprite(frames);

  setConfiguration(anim, character)

  // anim.play();

  app.stage.addChild(anim);

}


function onAssetsLoaded(loader, resources) {
  let character;
  for (let character in spriteDefinition) {
    //Do stuff where key would be 0 and value would be the object
    initCharacter(spriteDefinition[character])
  }

  app.start();

}

function toggleAll(play = false){
  if(play){
    app.stage.children.forEach( sprite => sprite.play())
    for (let character in spriteDefinition) {
      spriteDefinition[character].config.sound.mute(!play);
    }

  }
  else{
    for (let character in spriteDefinition) {
      spriteDefinition[character].config.sound.mute(!play);
    }
    app.stage.children.forEach( sprite => sprite.stop())
  }
}

function onClick(event) {
  if (!isSoundsStarted) {
    // Start all the sounds muted
    for (let character in spriteDefinition) {
      spriteDefinition[character].config.sound.mute(true);
      spriteDefinition[character].config.sound.play();
    }
    isSoundsStarted = true;
  }

  if (this.playing) {
    if(this.config.name == "Hanna"){
      toggleAll(false)
    }
    this.stop()
    this.config.sound.mute(true)
  } else {
    if(this.config.name == "Hanna"){
      toggleAll(true)
    }
    this.play();
    this.config.sound.mute(false)
  }

}
